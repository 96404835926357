import {session} from '../session/services/session';
import {experiments} from '../session/services/experiments';

export function getProductName() {
	const isRebrandedExperiments =
		experiments?.software_delivery_platform_rebranding ||
		session.siteParams.SOFTWARE_DELIVERY_PLATFORM_REBRANDING_EXPERIMENT === 'true';
	const isOnPremRebrandedExperiments =
		experiments?.software_delivery_platform_onprem_rebranding ||
		session.siteParams.SOFTWARE_DELIVERY_PLATFORM_ONPREM_REBRANDING_EXPERIMENT === 'true';
	const isSaasEnabled = session.isSaasEnabled;
	if (isRebrandedExperiments) {
		return isSaasEnabled
			? 'Core Software Delivery Platform'
			: isOnPremRebrandedExperiments
			? 'Software Delivery Management'
			: 'Software Delivery Platform';
	} else {
		return isSaasEnabled
			? 'ValueEdge'
			: isOnPremRebrandedExperiments
			? 'Software Delivery Management'
			: 'ALM Octane';
	}
}

window['getProductName'] = getProductName;
