import './authentication-point-module';

import '../ui/platform/logger/logger-module';
import '../ui/platform/logger/services/logger';
import '../ui/platform/logger/services/logger-decoration';
import '../ui/platform/session/session-module';
import '../ui/platform/session/services/ui-automation-helper';

import '../ui/platform/session/services/async-await-angular-adapter';

import 'platform/session/services/local-storage-service';
import '../ui/platform/translation/translation-module';
import '../ui/platform/translation/services/translate';
import '../ui/platform/translation/directives/translate/translate-directive';

import './services/connection-service';
import './services/url-utils-service';
import {localStorageService} from 'platform/session/services/local-storage-service';
import {testAutomationMode} from 'platform/session/services/ui-automation-helper';
import {getProductName} from '../ui/platform/services/product-name-service';

const loginModule = angular.module('alm.login.application');

loginModule.config(function (
	translateProvider,
	$httpProvider,
	CLIENT_TYPE_HEADER,
	CLIENT_TYPE_VALUE
) {
	'ngInject';
	translateProvider.registerNamespace('lg', 'login');
	$httpProvider.defaults.headers.common[CLIENT_TYPE_HEADER] = CLIENT_TYPE_VALUE;
});

loginModule.config(function (translateProvider) {
	'ngInject';
	translateProvider.declareMessages({
		'lg:login-connection-denied': 'Connection to this project has been denied.',
		'lg:login-not-supported-browser': 'This browser is not certified.',
		'lg:login-not-supported-browser-version': 'This browser version is not certified.',
		'lg:login-authentication-failed': 'Authentication failed',
		'lg:login-authentication-failed-cookies-disabled':
			'You must enable cookies in your browser to work with the client.',
		'lg:login-not-supported-version': 'Version {{version}} is not certified.',
		'lg:login-not-supported-versions': 'Versions {{versions}} are not certified.',
		'lg:login-recommended-version': 'We recommend version {{version}}.',
		'lg:login-select2-options-no-matches-found': 'No matches found.',
		'lg:login-select2-options-searching': 'Loading more results...',
		'lg:login-select2-options-loading-more-results': 'Searching...',
		'lg:web-ui-login-connect-project': 'Connect to project',
		'lg:web-ui-login-login': 'Login',
		'lg:web-ui-login-username': 'Name',
	});
});

loginModule.controller('alm.login.application.controller', function (
	$scope,
	$window,
	$document,
	$http,
	$log,
	$sce,
	$timeout,
	session,
	translate,
	urlUtilsService
) {
	'ngInject';
	$scope.initialized = false;

	var form = ($scope.form = {
		inputUsername: '',
		inputPassword: '',
	});
	$scope.loggingIn = false;

	var translationResourcesLoaded = translate.resourcesLoaded();
	if (!translationResourcesLoaded) {
		$log.error('Failed to load translation resources');
	}

	$scope.testAutomationMode = testAutomationMode;

	initialize();

	/**
	 * @name authenticationFailed
	 * @description Do steps that should be done after authentication fails
	 *
	 * Currently it lower the authentication flag
	 */
	function authenticationFailed(reason) {
		$log.error('Authentication failed');
		$log.error(angular.toJson(reason));

		$scope.authenticated = false;
		$scope.authenticating = false;
		$scope.invalidAuthentication = true;
	}

	(function ensureBrowserSupport() {
		$scope.ensureSupport = true;
	})();

	async function initialize() {
		$scope.productNameText = getProductName();
		// remove the if. (SoftwareDeliveryPlatformRebranding)
		if (session.siteParams.SOFTWARE_DELIVERY_PLATFORM_REBRANDING_EXPERIMENT === 'true') {
			$scope.translate = translate;
			$scope.SoftwareDeliveryPlatformRebrandingExperimentIsOn = true;

			$scope.productVersion = session.productVersion;
			let second_dot_index = session.productVersion.indexOf(
				'.',
				session.productVersion.indexOf('.') + 1
			);
			if (second_dot_index !== -1) {
				$scope.productVersion = session.productVersion.substr(0, second_dot_index);
			}
		} else {
			//in the pre-rebranded login dialog, if Chrome/Edge auto-fills an input, we want to display its label above the field.
			handleAutofilledInputs();
		}

		$scope.productBrandEditionDisplayName = session.productBrandEditionDisplayName;

		$log.log('Form user name: ' + form.inputUsername);
		$scope.invalidAuthentication = false;
		$scope.authenticated = false;
		$scope.authenticating = false;
		$scope.redirectUiApplication = urlUtilsService.hasRedirectInfo();
		$scope.isExternalAuthModeEnabled = session.isExternalAuthModeEnabled;
		$scope.loginLabel = translate(
			session.isExternalAuthModeEnabled
				? 'lg:web-ui-login-connect-project'
				: 'lg:web-ui-login-login'
		);
		$log.log('Redirect domain/project: ' + $scope.redirectUiApplication);
		$scope.authenticationFailedMessage = translate('lg:login-authentication-failed');
		$scope.loginNotice = $sce.trustAsHtml(session.loginNotice);

		if ($scope.isExternalAuthModeEnabled) {
			$log.log('Client use external authentication.');
		}

		$scope.nameLabel = session.siteParams.LOGIN_PAGE_NAME_LABEL
			? session.siteParams.LOGIN_PAGE_NAME_LABEL
			: translate('lg:web-ui-login-username');

		const originalUserName = form.inputUsername;
		const lastSavedName = await localStorageService.getItem<string>(':user');

		if (originalUserName === form.inputUsername && lastSavedName) {
			form.inputUsername = lastSavedName;
		}

		$scope.initialized = true;
	}

	/**
	 * @name authenticate
	 *
	 * @description Authenticate user to ALM server using given credentials
	 * @param username The user name
	 * @param password The password of user
	 * @return promise for operation
	 *
	 * Sends REST request to alm-authentication point.
	 */
	async function authenticate(username, password) {
		$scope.authenticating = true;

		let testCurrentUserResponse;
		let shouldAuthenticate = true;

		try {
			testCurrentUserResponse = await $http({
				method: 'GET',
				url: '../api/current_user',
			});
			if (testCurrentUserResponse.data.name === username) {
				shouldAuthenticate = false;
				$log.log('Successful authentication. User is already logged in the system.');
			}
		} catch (error) {
			shouldAuthenticate = true;
		}

		if (shouldAuthenticate) {
			$log.log(
				'Authenticate to server ' +
					urlUtilsService.getBaseAuthUrl() +
					' for user "' +
					username +
					'" ...'
			);
			const authData = angular.toJson({user: username, password: password});
			let response;
			try {
				// send authentication request to server
				response = await $http({
					method: 'POST',
					url: urlUtilsService.getBaseAuthUrl(),
					data: authData,
					headers: {
						'Content-Type': 'application/json',
						Accept: 'application/json',
					},
				});
			} catch (error) {
				// in case of authentication failure - update scope information about it
				authenticationFailed(error);
				$scope.$evalAsync();
				return;
			}
			// in case of authentication success - store authentication data and ask for domain/project list
			$log.log('Successful authentication. Status=' + response.status);
		}
		await localStorageService.setItem(':user', form.inputUsername);
		$log.log('Save user name in user data: ' + form.inputUsername);
		$scope.authenticated = true;
		$scope.authenticating = false;
		$scope.invalidAuthentication = false;
		$window.location = urlUtilsService.getRedirectTarget();
	}

	async function handleBackgroundImage() {
		let numberOfImages = 3;
		let localStorageKey = 'loginBackgroundImageIndex';
		let localStorageValue = await localStorageService.getItem<string>(localStorageKey);
		let imageIndex = localStorageValue ? parseInt(localStorageValue, 10) : 0;
		$scope.octaneLoginBackgroundClass = $scope.SoftwareDeliveryPlatformRebrandingExperimentIsOn
			? 'software-delivery-platform-login__wrapper'
			: 'octane-login__wrapper octane-login-background-' + (imageIndex % numberOfImages);

		// noinspection JSIgnoredPromiseFromCall
		await localStorageService.setItem(localStorageKey, '' + ((imageIndex + 1) % numberOfImages));
		return imageIndex;
	}

	// Authenticate
	$scope.submit = function () {
		authenticate(form.inputUsername, form.inputPassword);
	};

	// this function is called when user change username or password on login screen
	//
	// on IE this function is called also when user click on other places on the browser which cause problems
	// if client run behind SiteMinder. In this case, if function is called, authentication does not stop !
	$scope.changeAuthentication = function () {
		if ($scope.isExternalAuthModeEnabled) {
			$log.error(
				'changeAuthentication() was called by mistake (some IE bug) when client run in external authentication mode. This call is ignored.'
			);
			return;
		}

		$scope.wasAuthenticated = $scope.wasAuthenticated || $scope.authenticated;
		$scope.invalidAuthentication = false;
		$scope.authenticated = false;
		if (!form.inputUsername || form.inputUsername.length === 0) {
			$log.debug(
				'changeAuthentication() clear password field because username was set to empty value'
			);
			form.inputPassword = '';
		}
	};

	// check if capslock is on
	$scope.capsLockOn = false;

	$scope.checkCapsLock = function (e) {
		var s = String.fromCharCode(e.which);
		if (s.toUpperCase() === s && s.toLowerCase() !== s && !e.shiftKey) {
			$scope.capsLockOn = true;
		} else {
			$scope.capsLockOn = false;
		}
	};

	handleBackgroundImage();
	$scope.initialsSquareText = 'OCT';
	$scope.plusText = '+';
	$scope.admInitials = 'A';
	$scope.admName = 'APPLICATION DELIVERY MANAGEMENT';

	function handleAutofilledInputs() {
		function isAutofill(inputElmId: string) {
			const inputElm = $document.find(`#${inputElmId}`);
			if (inputElm.length === 0) {
				return false;
			}
			//hack - In Chrome/Edge, the only way to detect an auto-fill before any user interaction, is by its background color
			const autoFillBackgroundColors = ['rgb(250, 255, 189)', 'rgb(232, 240, 254)'];
			const inputFieldBackgroundColor = window.getComputedStyle(inputElm[0]).backgroundColor;
			return autoFillBackgroundColors.includes(inputFieldBackgroundColor);
		}
		$timeout(() => {
			if (isAutofill('inputUsername')) {
				$scope.inputUsernameIsAutofill = true;
			}
			if (isAutofill('inputPassword')) {
				$scope.inputPasswordIsAutofill = true;
			}
		}, 1000);
	}
});
